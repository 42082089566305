import "./Footer.css";

function Footer() {
  return (
    <div>
      <small>Copyright © 2022 | Nathaly Herrera</small>
    </div>
  );
}

export default Footer;
